@use '@tsp-ui/core/sass/utils';

.form {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);

  &.isAdd {
    max-width: 400px;
    margin: auto;
  }
}

.limitTypeButtons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);
  align-self: center;
}

.icon {
  font-size: utils.spacing(4);
}

.limitFields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);
}

.existingLimitButton {
  align-self: center;
}
