@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(1);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.row {
  display: flex;
  gap: utils.spacing(2);
  justify-content: space-between;
}

.actionsContainer {
  white-space: nowrap;
}

.additionalInfoMargin {
  margin-top: utils.spacing(1);
}

.expandButton {
  align-self: flex-end;
}
