@use '@tsp-ui/core/sass/utils';

.dialogPaper {
  min-width: 600px;
  min-height: 500px;
}

.headerContent {
  flex: 0 0 auto;
  padding-bottom: 0;
  display: flex;
  gap: utils.spacing(3);
  align-items: center;
  justify-content: space-between;
  overflow: visible;
  margin-bottom: utils.spacing(2);
}

.filterField {
  width: 260px;
}

.expandCollapseAllButtons {
  margin-left: utils.spacing(-1);
}

.headerDivider {
  margin: auto;
  width: calc(100% - #{utils.spacing(6)});
}

.mainContent {
  padding-top: utils.spacing(2);
}

.borderedList {
  display: flex;
  flex-direction: column;
}

.divider {
  grid-column: 1/-1;
  margin: utils.spacing(1);
}

.itemHeader {
  display: flex;
  gap: utils.spacing(0.5);
  align-items: center;
}

.fileList {
  border-left: 2px solid transparent;
  margin: utils.spacing(-1, 0, 0, 2);
  padding: utils.spacing(1, 0, 0, 4.5);
  position: relative;
  align-self: flex-start;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    height: calc(100% - 15px);
    border-left: 1px solid var(--app-color_border);
  }

  li {
    position: relative;

    &::marker {
      color: var(--app-color_border);
    }

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      left: utils.spacing(-4.5);
      width: calc(#{utils.spacing(2.5)} - 1px);
      border-bottom: 1px solid var(--app-color_border);
    }
  }
}

.fileContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(2);
  margin-right: -5px;
}

.fileDetailsPopoverPaper {
  padding: utils.spacing(1, 2, 2, 2);
}

.fileDetailsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(2);
}

.closeButton {
  margin-left: utils.spacing(-1);
}

.fileTimeline {
  margin: 0;
  padding: utils.spacing(0, 1);
}

.timelineItem {
  width: utils.spacing(3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemHeader {
  display: flex;
  gap: utils.spacing(1);
  align-items: center;
  justify-content: space-between;
}

.icons {
  display: flex;
  gap: utils.spacing(0.5);
  align-items: center;
}
