@use '@tsp-ui/core/sass/utils';

.paper {
  overflow: hidden;
  min-height: 500px;
  min-width: 700px;
}

.mainContent {
  background-color: var(--app-color_background);
  border-top: 1px solid var(--app-color_border);
  border-bottom: 1px solid var(--app-color_border);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  padding-top: utils.spacing(3) !important;
}

.noContentCard {
  padding: utils.spacing(1);
}

.editFormContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.expandedContent {
  display: flex;
  flex-direction: column;
  padding: utils.spacing(1, 2, 1, 2);
  gap: utils.spacing(1);
}
