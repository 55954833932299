@use '@tsp-ui/core/sass/utils';

.mainRow {
  padding: utils.spacing(1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(2);
}

.expandedRow {
  padding: utils.spacing(1);
  border-top: 1px solid var(--app-color_border);
  box-shadow: inset 0 6px 6px rgb(0 0 0 / 2%);
}

.dueDiligenceEntities {
  margin-bottom: utils.spacing(2);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.notesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;
  gap: utils.spacing(4);
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.addButton {
  margin-left: utils.spacing(-1);
  margin-right: auto;
}

.fileInput {
  grid-template-columns: 1fr 1fr;
}
