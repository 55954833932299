@use '@tsp-ui/core/sass/utils';


.colorPickerItem {
    display: flex;
    align-items: center;
    gap: utils.spacing(1);
    border-radius: var(--app-border-radius);
  
    &:not(:last-child) {
      margin-bottom: utils.spacing(2);
    }
}

.colorInput {
    width: 26px;
    height: 26px;
    border-radius: 4px;
    background-color: transparent;
    border-color: var(--app-color_border);
}