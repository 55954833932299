@use '@tsp-ui/core/sass/utils';

.sectionHeader {
  background-color: var(--app-color_background-medium);
  padding: utils.spacing(1.25);
  border-radius: var(--app-border-radius);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(2);
}

.highlightSection {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  margin-inline: utils.spacing(2);
  margin-top: utils.spacing(2);
}

.headerButton {
  margin-block: utils.spacing(-1);
}

.highlightTitle {
  margin-bottom: utils.spacing(-0.5);

  &:first-child {
    margin-top: utils.spacing(-0.5);
  }
}
