@use '@tsp-ui/core/sass/utils';

.root {
    display: flex;
    gap: utils.spacing(1);
    padding: utils.spacing(1);
    justify-content: space-between;

    &>span {
        white-space: nowrap;
    }
}

.popover {
    padding: utils.spacing(2);
}