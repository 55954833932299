@use '@tsp-ui/core/sass/utils';

.root {
    display: flex;
    gap: utils.spacing(2);
    align-items: flex-start;
    justify-content: space-between;
}

.section {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(1);
}

.headerButton {
    margin-top: utils.spacing(0.5);
}