@use '@tsp-ui/core/sass/utils';

.mainRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: utils.spacing(2);
}

.loanNumber {
  display: flex;
  align-items: center;
  white-space: nowrap;

  & > span {
    margin-right: utils.spacing(-1);
  }

  & > div {
    margin-left: utils.spacing(1);
  }

  & > b {
    font-weight: 500;
  }

  a {
    white-space: nowrap;
  }
}

.badge {
  top: utils.spacing(0.75);
  right: utils.spacing(0.75);
  height: 16px;
  width: 16px;
  min-width: 16px;
  font-size: 10px;
  overflow: hidden;
}

.skeleton {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -2px;
  left: -2px;
}

.wrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 100px;
}

.loanAmount {
  margin-right: utils.spacing(2);
}

.hidden {
  visibility: hidden;
}

.rateContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.borrower {
  margin-left: utils.spacing(2);

  &.customerView {
    margin-left: 0;
  }
}

.loanCardTransition_enter {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 150ms ease, transform 250ms ease;

  &_active {
    opacity: 1;
    transform: none;
  }

  @for $i from 1 through 20 {
    &:nth-child(#{$i}) {
      transition-delay: #{50 * $i}ms;
    }
  }
}

.chip {
  border-radius: var(--app-border-radius);
  color: var(--app-color_text-secondary);
  width: 150px;
  margin-left: utils.spacing(2);

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}
