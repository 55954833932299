@use '@tsp-ui/core/sass/utils';

.feeCard {
  padding: utils.spacing(1);
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;

  & > :first-child {
    flex: 1 1 auto;
  }
}

.chips {
  display: flex;
  gap: utils.spacing(1);
}

.chip {
  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
  padding: utils.spacing(0.25, 1);
}

.labels {
  display: flex;
  align-items: baseline;
}

.maxLabel {
  margin-left: utils.spacing(1);
}

.buttons {
  display: flex;
}

.progress {
  margin: utils.spacing(1, 0, 0, 1);
}
