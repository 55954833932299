@use '@tsp-ui/core/sass/utils';

.root {
  justify-self: start;
}

.content {
  display: flex;
  gap: utils.spacing(6);
  align-items: baseline;
}

.addresses {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);
}
