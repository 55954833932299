@use '@tsp-ui/core/sass/utils';

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(3);
}

.column {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.expandableContent {
  display: flex;
  flex-direction: column;
  padding: utils.spacing(1, 2, 1, 2);
  gap: utils.spacing(1);
}
