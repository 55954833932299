@use '@tsp-ui/core/sass/utils';

.button {
    padding: 0;
    margin-top: utils.spacing(-0.25);
    font-weight: 400;
}

.loader {
    margin: utils.spacing(2);
}
