@use '@tsp-ui/core/sass/utils';

.root {
  background-color: var(--app-color_background);
  border-top: 1px solid var(--app-color_border);
  border-bottom: 1px solid var(--app-color_border);
  padding-top: utils.spacing(3) !important;

  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}
