@use '@tsp-ui/core/sass/utils';

.matrixContainer {
  display: flex;
  flex-direction: column;
  height: 80vh;
}

.gridContainer {
  flex: 1 1 auto;
  display: flex;
  margin-top: utils.spacing(2);
}
