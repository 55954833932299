@use '@tsp-ui/core/sass/utils';

.root {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: utils.spacing(2);
    align-items: center;
    overflow-y: visible;
}

.categoryForm {
    display: grid;
    gap: utils.spacing(2);
    justify-content: center;
}

.noSupport {
    display: flex;
}

.gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: utils.spacing(2);
    text-align: center;
}

.categoryContainer {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    gap: utils.spacing(1);
  }

.categoryDropdown {
    width: 150px;
    text-align: left;
}

