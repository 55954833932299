@use '@tsp-ui/core/sass/utils';

.root {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(2);
}

.loanTypeOrLoanProgramButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: utils.spacing(2);
}

.icon {
    font-size: utils.spacing(5);
}

.help {
    align-self: center;
}

.divider {
    margin-inline: utils.spacing(-1.5);
}
