@use '@tsp-ui/core/sass/utils';

.pricingDetails {
  margin-left: auto;
  display: flex;
  gap: utils.spacing(1);
  align-items: center;
}

.initialLoader {
  margin: utils.spacing(2, -2, -2, -2);
}

.pricingControls {
  display: flex;
  gap: utils.spacing(2);
  margin-bottom: utils.spacing(2);
}

.lockInfo {
  margin-left: auto;
  align-self: flex-start;
}

.buttonBar {
  padding: utils.spacing(1);
  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: utils.spacing(0.25);
}

.noProductsFound {
  margin-bottom: utils.spacing(1);
}

.ineligibleProductsButton {
  margin-top: utils.spacing(1);
}
