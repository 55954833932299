@use '@tsp-ui/core/sass/utils';

.continueMergeProp {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: utils.spacing(3);
}

.iconTypography {
  flex-direction: column;
}
