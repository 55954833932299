@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: utils.spacing(4);
  align-items: start;

  & > :nth-child(odd) {
    justify-self: start;
  }

  & > :nth-child(even) {
    justify-self: end;
  }
}

.viewButton {
  margin-left: utils.spacing(-1);
}
