@use "@tsp-ui/core/sass/utils";

.singleSelectQuestion {
  padding: utils.spacing(2, 2, 1, 2);
  display: inline-flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.inlineSingleSelect {
  display: flex;
  gap: utils.spacing(1);
  flex-direction: row;
  justify-content: space-between;
}

.dropdown {
  width: 100%;
}

.underwritingAnswerButtons {
  display: flex;
  justify-content: flex-end;
  gap: utils.spacing(1);
  align-items: center;
}

.radios {
  margin-left: utils.spacing(2);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);

  & > label {
    height: utils.spacing(4);
  }
}
