@use '@tsp-ui/core/sass/utils';

.leftPanel {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  background-color: var(--app-color_primary_very-light);
  border-radius: var(--app-border-radius);
  padding: utils.spacing(2);
  margin-left: utils.spacing(-2);
  max-width: 250px;

  &.firstSelected {
    border-top-left-radius: 0;
  }
}

.dropZone {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: utils.spacing(2);

  background-color: var(--app-color_background);
  border-radius: var(--app-border-radius);
  border: 2px dashed var(--app-color_background-medium);
  padding: utils.spacing(2, 4);

  &.compact {
    gap: utils.spacing(0.5);
    padding: utils.spacing(0.5, 1);
  }

  &.dropZoneActive {
    background-color: var(--app-color_background-medium);
    border-color: var(--app-color_secondary);

    * {
      pointer-events: none;
    }
  }

  &.hidden {
    display: none;
  }
}

.documentsContainer {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);

  background-color: var(--app-color_background);
  padding: utils.spacing(1);

  max-height: 200px;
  overflow: auto;

  &.compact {
    gap: utils.spacing(0.5);
    padding: utils.spacing(0.5);
  }

  &.noUploads {
    align-self: start;
  }
}

.statusCard {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--app-color_primary);
  background-color: var(--app-color_primary_very-light);
  color: var(--app-color_primary);

  & p {
    font-weight: 500;
  }
}

.statusHeader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lockButton {
  padding: utils.spacing(.5);
  position: absolute;
  right: 0;
  top: -2px;

  & button {
    margin: -6px -6px auto;
    color: inherit;
  }
}

.documentsSection {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(.5);
}

.righthandContent {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.endAdornment {
  position: absolute;
  right: utils.spacing(0);
  top: utils.spacing(2.5);
}

.submitButton {
  width: 200px;
  align-self: flex-end;
}

.approvalStatusIcon {
  margin-left: utils.spacing(.5);
}

.neutralIcon {
  color: rgba(0, 0, 0, 0.75);
}

.loader {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
}
