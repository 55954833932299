@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(1);
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  align-items: center;
  gap: utils.spacing(2);
  max-width: 600px;
}

.optionalChip {
  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
  padding: utils.spacing(0.5, 1);

  &.hidden {
    visibility: hidden;
  }
}
