@use '@tsp-ui/core/sass/utils';

.matrixStep {
  min-width: 600px;
}

.stepperContent {
  flex: 0 0 auto;
  padding-bottom: 0;
}

.stepper {
  width: 350px;
  margin-inline: auto;
  margin-top: utils.spacing(1);
}

.detailsStepForm {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);

  .dateFields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: utils.spacing(2);
  }
}

.switches {
  display: flex;
  flex-direction: column;
}

.columnAutocomplete {
  margin-top: utils.spacing(2);
}
