@use '@tsp-ui/core/sass/utils';

.productTypeRow {
  display: flex;
  align-items: center;
  gap: utils.spacing(1);
  padding-inline: utils.spacing(1);

  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
}

.numProducts {
  margin-left: auto;
}

.productRowList {
  margin: utils.spacing(0, 2, 1, 2);
}
