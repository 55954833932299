@use '@tsp-ui/core/sass/utils';

.root {
    display: flex;
    justify-content: space-between;
}

.section {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(1);
}

.chip {
  border-radius: var(--app-border-radius);
  color: var(--app-color_text-secondary);
  margin-left: utils.spacing(2);

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}

.headerButton {
    margin-top: utils.spacing(0.5);
}