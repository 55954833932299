@use '@tsp-ui/core/sass/utils';

.popoverPaper {
    display: flex;
    flex-direction: column;
    min-width: 700px;
}

.tableRoot {
    padding: utils.spacing(1, 2);
    border: none;
    border-radius: 0;
}

.iconCell {
    width: 1px;
    white-space: nowrap;
}

.header {
    padding: utils.spacing(0.5, 2);
    background-color: var(--app-color_primary);
    color: white;
}

.check {
    transform: translate(30%, 20%);
}

.popoverLoading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 250px;
    min-width: 450px;
    padding: utils.spacing(2);
    gap: utils.spacing(1);
}
