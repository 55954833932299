@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  gap: utils.spacing(1);
  align-items: center;
  padding: utils.spacing(0.5, 1);
  border: 1px solid transparent;
  margin: -1px;

  &.active {
    background-color: rgb(237, 247, 237);
  }

  &.pending {
    background-color: rgb(255, 244, 229);
  }

  &.expired {
    background-color: rgb(253, 237, 237);
  }

  &.selected {
    &.active {
      border-color: var(--app-color_success);
    }

    &.pending {
      border-color: var(--app-color_warn);
    }

    &.expired {
      border-color: var(--app-color_error);
    }
  }

  & > :first-child {
    flex: 1;
  }
}

.header {
  display: flex;
  align-items: baseline;
  gap: utils.spacing(2);
  justify-content: space-between;
}
