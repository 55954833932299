@use '@tsp-ui/core/sass/utils';

.sectionHeader {
    display: flex;
    justify-content: space-between;
    gap: utils.spacing(2);
    align-items: center;
    margin-bottom: utils.spacing(-1);
}

.dueDiligenceEntityCardSection {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}