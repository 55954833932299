@use '@tsp-ui/core/sass/utils';

.suspendedAlert {
  padding: utils.spacing(1);
  margin-bottom: utils.spacing(2);
}

.availabilityAlert {
  @extend .suspendedAlert;
  border: none;
}

.alertTitle {
  margin-bottom: 0;
}

.sections {
  display: flex;
  justify-content: space-between;
  gap: utils.spacing(3);
}

.section {
  min-width: 400px;
}
