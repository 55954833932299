@use '@tsp-ui/core/sass/utils';


.switches {
    padding: utils.spacing(0, 2);
    display: grid;
    gap: utils.spacing(1);
    grid-template-columns: auto 1fr;

    & > label {
        align-self: center; // vertically centers the text in the label
    }
}

.secondaryText {
    margin-left: utils.spacing(2);
}

.saveButton {
    grid-column: 1/-1;
    justify-self: end;
    display: flex;
    gap: utils.spacing(2);
    align-items: center;
}