@use '@tsp-ui/core/sass/utils';

.detailsPaper {
  padding: utils.spacing(2);
  margin-top: utils.spacing(3);
}

.matrixContainer {
  margin-top: utils.spacing(3);
  min-width: 550px;
}

.stepper {
  width: 350px;
  margin-inline: auto;
  margin-top: utils.spacing(1);
}

.buttons {
  display: flex;
  gap: utils.spacing(2);
  margin-top: utils.spacing(3);
}

.loader {
  margin-top: utils.spacing(2);
  width: 600px;
}
