@use '@tsp-ui/core/sass/utils';

.progressContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.progress {
  width: 24px;
  height: 24px;
  position: relative;
  margin-top: -2px;
}
