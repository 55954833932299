@use '@tsp-ui/core/sass/utils';

.root {
    padding: utils.spacing(1);
    width: fit-content;
}

.displayHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: utils.spacing(-1);

    & p {
        font-weight: 500;
    }
}