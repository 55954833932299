@use '@tsp-ui/core/sass/utils';

.form {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(2);
}

.formLabel {
    font-weight: 700;
}

.detailsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: utils.spacing(2);
    margin-top: utils.spacing(1);
    align-items: center;
}