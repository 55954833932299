@use '@tsp-ui/core/sass/utils';

.header {
  display: flex;
  gap: utils.spacing(1);
  align-items: center;
}

.divider {
  margin: utils.spacing(-1, -2, 3, -2);
}

.content {
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: utils.spacing(1, 3);
}

.listRoot {
  margin-left: utils.spacing(-1);
}
