@use '@tsp-ui/core/sass/utils';

.formContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: utils.spacing(2);
    width: 500px;
}

.fullWidth {
    grid-column: 1/-1;
}

.chip {
    background-color: white;
}
