@use '@tsp-ui/core/sass/utils';

.header {
    border-radius: var(--app-border-radius);
    background-color: var(--app-color_background-medium);
    font-weight: 500;
    padding: utils.spacing(0.5, 2);
}

.alerts {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(0.5);
    margin-top: utils.spacing(2);
    padding-inline: utils.spacing(2);
}