@use "@tsp-ui/core/sass/utils";

.underwritingStepSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: utils.spacing(3);
}

.underwritingStepSectionTitle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .addButton {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.submitButtonContainer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .loading {
    position: absolute;
    left: 100%;
    margin-left: utils.spacing(1); // the space between the button and the loader
  }
}
