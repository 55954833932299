@use '@tsp-ui/core/sass/utils';

.root {
    display: flex;
    gap: utils.spacing(2);
    justify-content: space-between;
    margin-top: utils.spacing(3);
}

.items {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(1);
    min-width: 250px;
}

.header {
    display: flex;
    align-items: center;
    gap: utils.spacing(1);
    justify-content: space-between;
    width: 100%;
}
