@use '@tsp-ui/core/sass/utils';

.root {
  overflow: hidden;
}

.expandableContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: utils.spacing(2);
  align-items: flex-start;
}

.section {
  height: fit-content;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);

  & > :nth-child(2) {
    margin-top: utils.spacing(-1);
  }
}

.docContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: utils.spacing(1);
  grid-template-columns: 1fr 2fr;
}

.column {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.fileInputContainer {
  position: relative;
}

.fileUploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: utils.spacing(2);
}

.addAnotherButton {
  margin-top: utils.spacing(-0.75);
}

.fileUploadLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: var(--app-border-radius);
  display: grid;
  align-items: end;
  overflow: hidden;
}

.additionalDetails {
  margin-left: auto;
  display: flex;
  gap: utils.spacing(1);
  align-items: center;
}


.loanStatusButton {
  margin-left: auto;
  align-self: center;
}

.editPaper {
  padding: utils.spacing(0.5, 1);
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  background-color: var(--app-color_background-medium);
}

.adeIconButton {
  margin-block: utils.spacing(-1);
}
