@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: utils.spacing(4);
}

.container {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: utils.spacing(4);
  margin-bottom: utils.spacing(2);
}

.percentageContent {
  display: grid;
  gap: utils.spacing(2);
  grid-template-columns: 1fr 1fr;
}

.loanAmount {
  flex: 1;
}

.percentageInput {
  width: 93px; // min width to show ##.###%
}

.firstRow {
  display: flex;
  gap: utils.spacing(1);
  grid-column: span 2;
  align-items: flex-end;
}

.separationText {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: utils.spacing(1.125);
}

.tableHeader {
  display: flex;
  justify-content: space-between;
}

.feesTable {
  margin-top: utils.spacing(-1);
}

.divider {
  background-color: transparent !important;
  border: none !important;
}

.additionalFees {
  margin-bottom: utils.spacing(.25);
}
