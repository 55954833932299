@use '@tsp-ui/core/sass/utils';

.root {
    border-color: var(--app-color_error);
    border-radius: utils.spacing(0.5);
    background-color: rgb(253, 237, 237);
    overflow: hidden;
}

.mainRow {
    display: flex;
    align-items: center;
    gap: utils.spacing(1);
    padding-right: utils.spacing(1);
}

.primaryText {
    flex-grow: 1;
    margin-left: utils.spacing(1);
}

.expandedRow {
    border-top: 1px solid var(--app-color_border);
    padding-right: utils.spacing(2);
    overflow: hidden;
    box-shadow: inset 0 6px 6px rgb(0 0 0 / 2%);
    background-color: white;
}