@use '@tsp-ui/core/sass/utils';

.root {
    display: flex;
    gap: utils.spacing(1);
    padding: utils.spacing(1);
    justify-content: space-between;
    min-width: 500px;
}

.icons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.info {
    margin: utils.spacing(0, 1);
}
