@use '@tsp-ui/core/sass/utils';

.root {
  padding-inline: utils.spacing(1);
}

.content {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
}

.fields {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: utils.spacing(2);
  align-items: center;
  margin-bottom: utils.spacing(1);
}

.productTypeSwitch {
  margin-right: auto;
}

.chip {
  padding: utils.spacing(0.25, 1);
  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
  margin-block: utils.spacing(0.5);
}
