@use '@tsp-ui/core/sass/utils';
@use 'src/scss/premicorr-theme';

.unsetMaxWidth {
  max-width: unset !important;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: utils.spacing(2);
  grid-column: 1/-1;
  margin-top: utils.spacing(3);
}

.stepper {
  width: 350px;
  margin-bottom: utils.spacing(4);
}

.matrixFormRoot {
  flex: 1;
  width: 100%;
}

.detailsFields {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: utils.spacing(2);
  width: 550px;
  padding: utils.spacing(3);
}

.baseEligibilityButtons {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.gridContainer {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
}

.gridSetupContainer {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  align-items: center;
}

.gridSetupPaper {
  padding: utils.spacing(2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: utils.spacing(2);
  width: 600px;
}

.propertiesTextField {
  align-self: stretch;
}

.innerGridContainer {
  height: 100%;
  display: flex;
}

.exclusionPaper {
  width: auto;
}

.filterContainer {
  padding: utils.spacing(1.5, 2);
  background-color: var(--app-color_background);
  margin-top: utils.spacing(3);
}

.exclusionQuestionPaper {
  padding: utils.spacing(2, 2, 1, 2);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  align-items: flex-end;
}

.loanProgramChip {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  white-space: nowrap;
  padding: utils.spacing(0.25, 0.5);
  color: var(--app-color_text-secondary);
  text-decoration: none;

  &:hover {
      cursor: pointer;
      box-shadow: var(--shadow-1);
  }

  &.selected {
    background: rgba(255, 255, 255, .2)
  }
}

.select {
  padding-top: utils.spacing(1);
}

.inheritOrCreateButton {
  flex-direction: column;
  gap: utils.spacing(2);
  padding: utils.spacing(2);
  height: 100%;
}

.icon {
  font-size: utils.spacing(5);
}

.divider {
  margin-inline: utils.spacing(-1.5);
}
