@use '@tsp-ui/core/sass/utils';

.root {
  padding: utils.spacing(1, 2);
}

.label {
  margin-top: utils.spacing(1);
}

.guidelineContainer {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.button {
  margin-left: utils.spacing(-0.5);
}

.addButtonContainer {
  display: flex;
  justify-content: center;
  margin-top: utils.spacing(2);
}
