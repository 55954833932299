@use "@tsp-ui/core/sass/utils";

.tableCard {
  padding: utils.spacing(0.5, 1, 1, 1);
}

.editableCardTable {
  margin-bottom: utils.spacing(2);
  margin-top: utils.spacing(1);

  & > table {
    width: 100%;
  }

  table > tbody > tr > td {
    padding-bottom: utils.spacing(0.5);
    padding-top: utils.spacing(0.5);
  }

  input {
    font-size: 14px;
  }
}

.tableCardButtons {
  display: flex;
  gap: utils.spacing(1);
  justify-content: flex-end;
}
