@use '@tsp-ui/core/sass/utils';

.root {
    display: flex;
    gap: utils.spacing(4);
}

.logo {
    height: 44px;
}

.colorsContainer {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(.5);
}

.colorDisplayBox {
    display: flex;
    gap: utils.spacing(.5);
    align-items: center;
}

.colorDisplay {
    height: 22px;
    width: 22px;
    border-radius: 4px;
    border: 1px solid var(--app-color_border);
}