@use '@tsp-ui/core/sass/utils';

.productCardContainer {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  padding-inline: utils.spacing(2);
}

.card {
  padding-inline: utils.spacing(1);
}
