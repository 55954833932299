@use '@tsp-ui/core/sass/utils';

.dialogPaper {
  min-width: 500px;
}

.formShown {
  min-height: 630px !important;
}

.header:not(:first-child) {
  margin-top: utils.spacing(1);
}

.card {
  padding: utils.spacing(0.5, 1);
  margin-inline: utils.spacing(2);
  display: grid;
  grid-template-columns: 1fr 120px 60px auto;
  align-items: center;
  gap: utils.spacing(2);
}

.limitAmount {
  justify-self: center;
}

.noResultsPaper {
  padding: utils.spacing(1);
  margin-inline: utils.spacing(2);
}
