@use '@tsp-ui/core/sass/utils';

.root {
    display: flex;
    align-items: center;
    gap: utils.spacing(0.5);
    overflow: hidden;
}

.fileTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}