@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex-direction: column;
  padding: utils.spacing(2);
  gap: utils.spacing(1);
  overflow-y: auto;
  max-height: 42vh;
}

.noUploadsTransition_appear {
  transition: opacity 250ms ease 250ms;
  opacity: 0;

  &_active {
    opacity: 1;
  }
}

.batchCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(2);
}

.batchCard {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(0.5);
  padding: utils.spacing(0.5, 1, 1, 1);
}

.fileCardTransition {
  &_enter {
    opacity: 0;
    transform: translateY(-100%);

    &_active {
      opacity: 1;
      transform: none;
      transition: opacity 150ms ease,
        transform 250ms ease;
    }

    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        transition-delay: #{50 * $i}ms;
      }
    }
  }

  &_exit {
    opacity: 1;
    transition: opacity 250ms ease;

    &_active {
      opacity: 0;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: utils.spacing(2);
}