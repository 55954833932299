@use '@tsp-ui/core/sass/utils';

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.filterContainer {
  display: flex;
  align-items: flex-end;
  gap: utils.spacing(2);
}

.filterField {
  width: 225px;
}

.root {
  display: grid;
  grid-template-columns: 7fr 3fr;
  flex: 1 1 auto;
  overflow: hidden;
  min-height: 0;
  gap: utils.spacing(4);
}

.sections {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  overflow-y: auto;
  height: 100%;
}

.sidebar {
  display: grid;
  grid-template-rows: auto 1fr;
  gap: utils.spacing(3);
  align-items: start;
}
