@use '@tsp-ui/core/sass/utils';

.dialogRoot {
    width: 575px;
}

.formShown {
    width: 800px;
    height: 500px;
}

.dialogContent {
    display: flex;
    flex-direction: column;
    padding: utils.spacing(3);
}

.pagination {
  display: flex;
  justify-content: center;
}

.currentHeader {
    background-color: var(--app-color_background-medium);
    padding: utils.spacing(1.25);
    border-radius: var(--app-border-radius);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card {
    display: grid;
    padding: utils.spacing(1);
    margin: utils.spacing(1);
}

.expiredRatesheets {
    margin-top: utils.spacing(2);
}

.sheetInfo {
    display: flex;
    margin-bottom: utils.spacing(0.5);
}

.dates {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    gap: utils.spacing(2);
}

.createdBy {
    margin-left: auto;
}

.button {
    padding: 0;
    margin-top: utils.spacing(-0.25);
    font-weight: 400;
}
