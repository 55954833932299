@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  align-items: flex-start;
  justify-content: space-between;
}

.configs {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  min-width: 250px;
}

.description {
  max-width: 300px;
}

.configDescriptionContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
