@use '@tsp-ui/core/sass/utils';

.root {
    padding: utils.spacing(1);
    display: grid;
    grid-template-columns: 1fr auto;

    justify-content: space-between;
    gap: utils.spacing(0.5, 1);
}

.description {
    display: flex;
    width: 300px;
    white-space: wrap;
}

.createdBy {
    align-self: flex-end;
}

.actionsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    white-space: nowrap;
    margin: utils.spacing(-0.5, -0.5);
}

.button {
    align-self: center;
}
