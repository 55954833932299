@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.switches {
  display: flex;
  flex-direction: column;
}
