@use "@tsp-ui/core/sass/utils";

.leftPanel {
  background-color: var(--app-color_primary_very-light);
  border-radius: var(--app-border-radius);
  padding: utils.spacing(2);
  margin-left: utils.spacing(-2);
}

.relevantDocs {
  padding: utils.spacing(0.5, 1);
  margin-bottom: utils.spacing(2);
}

.files {
  list-style: none;
  padding: 0;
  margin: 0;
}

.allConditionsStep {
  margin-bottom: utils.spacing(2);
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
  border-radius: var(--app-border-radius);
}

.stepChecklistItemContainer {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
  border-radius: var(--app-border-radius);
}

.extraWidth {
  width: calc(100% + #{utils.spacing(1)});
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.selected {
  font-weight: 500;
}
