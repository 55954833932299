@use '@tsp-ui/core/sass/utils';

.mainContent {
  background-color: var(--app-color_background);
  border-top: 1px solid var(--app-color_border);
  border-bottom: 1px solid var(--app-color_border);
  padding-top: utils.spacing(3) !important;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 1400px;
  gap: utils.spacing(4);
  align-items: start;

  & > div {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(1);
  }
}

.noResults {
  display: none;

  &:nth-child(2) {
    display: block;
  }
}
