@use '@tsp-ui/core/sass/utils';

.documentCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: utils.spacing(0, 2);

    &.compact {
        padding: utils.spacing(0, 1);
    }
}