@use '@tsp-ui/core/sass/utils';

.card {
    padding: utils.spacing(1);
    display: flex;
    gap: utils.spacing(2);
    align-items: center;
    justify-content: space-between;
  
    & > :first-child {
      flex: 1 1 auto;
    }
}

.buttons {
    display: flex;
}

.props {
    display: flex;
    flex-direction: column;
  }

.progress {
    padding: utils.spacing(1);
}