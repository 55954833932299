@use '@tsp-ui/core/sass/utils';

.contactInfo {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(0.5);
    padding: utils.spacing(1);

    & > :global(.MuiTypography-root) {
      display: flex;
      align-items: center;
      gap: utils.spacing(1);
    }
}

.avatar {
    align-self: baseline;
    height: utils.spacing(4);
    width: utils.spacing(4);
    font-size: 14px;
    background-color: var(--app-color_primary);
    transition: box-shadow 0.3s ease;
    margin-top: utils.spacing(1.5);

    &:hover {
        cursor: pointer;
        box-shadow: var(--shadow-6);
        transform: scale(1.015);
    }
}

.nameLink {
    display: inline;
}
