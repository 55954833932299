@use "@tsp-ui/core/sass/utils";

.forEachQuestionCard {
  max-width: 650px;
  padding: utils.spacing(0, 1, 1, 1);
  display: flex;
  flex-direction: column;
}

.forEachQuestionDialog {
  min-width: 450px;
}

.header {
  display: flex;
  justify-content: space-between;
  gap: utils.spacing(2);
  align-items: center;
}

.iconButtons {
  display: flex;
  gap: utils.spacing(0.5);
  justify-content: flex-end;
  margin-right: utils.spacing(-1);
}
