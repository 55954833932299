@use '@tsp-ui/core/sass/utils';

.root {
  position: relative;
  width: 450px;
  padding: utils.spacing(3);
  overflow: hidden;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: utils.spacing(2);
  margin-top: utils.spacing(3);
}
