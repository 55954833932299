@use '@tsp-ui/core/sass/utils';


.logoPreviewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: utils.spacing(2);
}

.logoPreview {
    width: 300px;
    border: solid 1px var(--app-color_border);
    border-radius: 4px;
    padding: utils.spacing(1);
}

.hidden {
    display: none;
}