@use '@tsp-ui/core/sass/utils';

.activeClientsPopover {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(.5);

  h6 {
    padding: utils.spacing(0.5, 2);
    background-color: var(--app-color_primary);
    color: white;
  }
}

.premManagedCard {
  padding: utils.spacing(2, 2, 1, 2);
}

.button {
  margin-left: utils.spacing(-1);
  margin-top: utils.spacing(0.5);
}

.noExclusions {
  margin-top: utils.spacing(0.5);
}

.exclusions {
  justify-self: end;
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  max-width: 400px;

  .caption {
    max-width: 370px;
    align-self: center;
  }
}

.noExclusions {
  margin-top: utils.spacing(0.5);
}
