@use '@tsp-ui/core/sass/utils';

.headerContainer {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  width: 100%;
}

.header {
  width: 100%;
}

.liabilities {
  position: relative;
  align-self: stretch;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr auto;
  gap: utils.spacing(2, 3);
  padding-bottom: utils.spacing(5);

  .addButton {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.specialLiabilities {
  display: flex;
  flex-direction: column;

  .addSpecialButton {
    margin-top: utils.spacing(2);
    align-self: flex-end;
  }
}

.questionsWrapper {
  align-self: center;
  width: 550px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(0.5);
}

.sectionLabel {
  margin-bottom: utils.spacing(-0.5);
}

.omitLabel {
  margin-top: utils.spacing(1);
}

.activeLiabilities {
  grid-row: 1 / span 1;
}

.rightAlign {
  text-align: right;
}

.bold {
  font-weight: 500;
}

.summary {
  padding: utils.spacing(1);
  grid-column-start: 2;
  justify-self: end;
  display: flex;
  align-items: flex-end;
  gap: utils.spacing(3);
}

.plus {
  color: var(--app-color_success);
}

.minus {
  color: var(--app-color_error);
}

.minusLetterSpacing {
  letter-spacing: 2px;
}

.questions {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(3);
  padding: utils.spacing(2);
}

.questionGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.questionText {
  font-weight: 500;
  color: var(--app-color_text-primary);
}

.buttonGroup {
  display: flex;
  gap: utils.spacing(1);
}

.specialLiabilitiesTable {
  margin-top: utils.spacing(4);
  align-self: center;

  th:not(:first-child), td:not(:first-child) {
    text-align: center;
  }
}

.iconTableCell {
  width: 1px;
  white-space: nowrap;
}