@use '@tsp-ui/core/sass/utils';

.dialogPaper {
  min-width: 850px;
  min-height: 800px;

  &.isDocument {
    display: grid;
    grid-template-rows: auto 1fr auto;
  }
}

.documentDialogContent {
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden; //TODO post-demo figure out styles here
}

.title {
  display: flex;
  align-items: baseline;
}

.documentContentContainer {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 300px 1400px;
  grid-template-rows: 1fr;
}

.headerContent {
  flex: 0 0 auto;
  padding-bottom: 0;
  display: flex;
  gap: utils.spacing(1);
  align-items: center;
  justify-content: space-between;
  overflow: visible;
  margin-bottom: utils.spacing(1);
}

.content {
  display: grid;
  grid-template-columns: minmax(250px, auto) auto;
  gap: utils.spacing(6);

  &.documentsShown {
    grid-template-columns: auto minmax(250px, auto) auto;

    .column:first-child {
      position: sticky;
      top: 0;
      height: fit-content;
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      padding-right: utils.spacing(1);
    }
  }
}




.column {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
  max-width: 500px;
}

// TODO post-demo make a component for this
.header {
  border-radius: var(--app-border-radius);
  background-color: var(--app-color_background-medium);
  font-weight: 500;
  padding: utils.spacing(0.5, 2);
}

.documentLink {
  display: block;
}

.section {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  gap: utils.spacing(0, 2);
  padding: utils.spacing(1, 2, 0, 2);
}

.infoHeader {
  grid-column: 1/-1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(2);
}

.icons {
  display: flex;
  gap: utils.spacing(0.5);
  align-items: center;
}

.diffValue {
  display: flex;
  gap: utils.spacing(1);
  align-items: baseline;
}

.showArmButton {
  justify-self: right;
  margin-right: -5px;
}

.armDetails {
  grid-column: 1/-1;
  justify-self: end;
}

.borrowers {
  padding: utils.spacing(1, 2, 0, 2);
  display: flex;
  flex-direction: column;
}

.borrowerCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &> :first-child {
    display: none;
  }
}

.borrowerDetails {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: utils.spacing(1);
  margin-top: utils.spacing(0.5);
}

.divider {
  grid-column: 1/-1;
  margin: utils.spacing(1);
}

.borrowerName,
.ssn {
  cursor: pointer;
  color: var(--app-color_primary);

  &:hover {
    text-decoration: underline;
  }
}

.addressWrapper {
  grid-column: 1/-1;
}

.propertyAddress {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: utils.spacing(1);
}

.valueContainer {
  display: flex;
  gap: utils.spacing(.5);
}

.justifyStart {
  justify-self: start;
}

.justifyEnd {
  justify-self: end;
}

.menuItem {
  background-color: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.selected {
  font-weight: 500;
  background-color: var(--app-color_primary-light) !important;
}

.expandedContent {
  padding: utils.spacing(1, 2, 1, 2);
  overflow: hidden;
}

.dataDiffExpandedContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(2);
}

.documentDataContainer {
  background-color: var(--app-color_primary-light);
  padding: utils.spacing(1);
}

.documentDataPaper {
  background-color: var(--app-color_background);
  padding: utils.spacing(1, 1, 0, 1);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.iframe {
  width: 100%;
  height: 500px;
  border: none;
  border-radius: var(--app-border-radius);
}

.paper {
  padding: utils.spacing(1);
  display: flex;
  flex-direction: column;
}

.diffRoot {
  display: flex;
  align-items: baseline;
  gap: utils.spacing(1);
}
