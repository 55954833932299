@use '@tsp-ui/core/sass/utils';


.root {
    :global(.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list) {
        padding: 0;
    }
}

.menuTitle {
    padding: utils.spacing(0.5, 2);
    background-color: var(--app-color_primary);
    color: white;
}