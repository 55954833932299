@use '@tsp-ui/core/sass/utils';

.dialogContent {
  display: grid;
  grid-template-columns: auto 1fr;

  & > div {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(1);
  }

  & > :nth-child(2) {
    min-width: 300px;
    margin-left: utils.spacing(6);
  }
}

.statusContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(3);
  color: var(--app-color_text-secondary);

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: utils.spacing(0.5);
    padding: utils.spacing(1, 2, 0.5, 2);
  }
}

.files {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.fileCardContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: utils.spacing(1);
  align-items: center;
}

.downloadContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hidden {
  visibility: hidden;
}

.revFileHeader {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
}

.revisionFileInput {
  margin-block: utils.spacing(1) !important;
}

.progressTimeline {
  position: relative;
  padding: 0;
  margin-top: utils.spacing(-1);
}

.timelineContent {
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-align: left;
}

.hiddenConnector {
  visibility: hidden;
}

.showCreateRevisionButton {
  display: block;
  margin-left: -5px;
  margin-top: utils.spacing(-0.5);
}
