@use '@tsp-ui/core/sass/utils';

.alertCard {
  padding: utils.spacing(1);
  display: flex;
  align-items: center;
}

.alertActions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1 1 auto;
}

.date {
  flex: 0 0 auto;
  margin-left: auto;
  white-space: nowrap;
  margin-block: utils.spacing(-0.5);
}

.alertIcons {
  display: flex;
  align-items: center;
  gap: utils.spacing(0.5);
  margin-top: -5px;
  margin-right: -5px;
}