@use '@tsp-ui/core/sass/utils';

.popoverPaper {
  display: flex;
  flex-direction: column;
  width: 400px;
  max-height: 70vh;
  padding-bottom: utils.spacing(1);

  h6 {
    padding: utils.spacing(0.5, 2);
    background-color: var(--app-color_primary);
    color: white;
  }
}

.button {
  align-self: center;
}

.versionChip {
  padding: utils.spacing(0.5, 1);
  border-radius: var(--app-border-radius);
  border: 1px solid;

  &.active {
    background-color: rgb(237, 247, 237);
    border-color: var(--app-color_success);
  }

  &.pending {
    background-color: rgb(255, 244, 229);
    border-color: var(--app-color_warn);
  }

  &.expired {
    background-color: rgb(253, 237, 237);
    border-color: var(--app-color_error);
  }
}
