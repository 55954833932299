@use '@tsp-ui/core/sass/utils';

.card {
  padding: utils.spacing(0.5, 2, 1, 2);
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(1);
  margin-bottom: utils.spacing(0.5);
}

.button {
  margin-inline: utils.spacing(-1);
}
