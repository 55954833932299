@use '@tsp-ui/core/sass/utils';

.productsButton {
  margin-left: utils.spacing(-1);
}

.productTypesContainer {
  margin-bottom: utils.spacing(0.5);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}
