@use '@tsp-ui/core/sass/utils';

.root {
  position: relative;
  min-width: 100px;
  height: 34px;

  &:hover .actions {
    opacity: 1;
  }

  &.editing {
    padding-block: utils.spacing(0.25) !important;
    padding-right: utils.spacing(4);
  }
}

.actions {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  background-color: var(--app-color_background);

  &.show, &:has(:focus) {
    transition: opacity 250ms ease;
    opacity: 1;
  }
}
