@use '@tsp-ui/core/sass/utils';

.root {
    display: flex;
    gap: utils.spacing(2);
    flex-direction: column;
}

.section {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(1);
}

.conditionConfigGroups {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: utils.spacing(2);
}

.buttons {
    white-space: nowrap;
}
