@use '@tsp-ui/core/sass/utils';

.exclusionCardContainer {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  flex-wrap: wrap;
  margin-top: utils.spacing(2);
  justify-content: center;
}

.exclusionCard {
  padding: utils.spacing(1);
}

.logicContainer {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
}

.button {
  margin-left: utils.spacing(-1);
  margin-top: utils.spacing(0.5);
}
