@use '@tsp-ui/core/sass/utils';


.buttons {
  display: flex;
}

.choiceCard {
    padding: utils.spacing(0.5, 1);
    display: flex;
    flex-direction: column;
    gap: utils.spacing(0.5);
    overflow: hidden;
    background-color: var(--app-color_background);

    &.isEditing {
        padding: utils.spacing(1);
    }
}

.headerEditing {
  display: flex;
  gap: utils.spacing(0.5);
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  flex: 1;
  min-width: 150px;
}

.headerContainer {
  padding-left: utils.spacing(1);
  background-color: var(--app-color_background-medium);
  display: flex;
  gap: utils.spacing(0.5);
  align-items: center;
  justify-content: space-between;
  margin: utils.spacing(-0.5, -1, 0, -1);
  line-height: 1;
  flex: 1;
  min-width: 150px;
}

.editSideEffectCard {
  display: flex;
  align-items: center;
}

.displaySideEffectCard {
  padding: utils.spacing(0.5);
}

.fullWidth {
  width: 100%;
}

.sideEffectsHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: utils.spacing(2);
}

.sideEffectFields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: utils.spacing(2);
  width: 100%;
  padding: utils.spacing(1);
}