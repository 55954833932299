@use '@tsp-ui/core/sass/utils';

.root {
    min-height: 450px;
}

.dialogFormRoot {
    padding: utils.spacing(3, 3, 1, 3);
}

.uploadText {
    text-align: center;
}

.fileInputContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.date {
    display: flex;
    flex-direction: row;
    padding: utils.spacing(2, 1, 2);
    gap: utils.spacing(2);
    justify-content: center;
}

.fileInput {
    display: grid;
    padding: utils.spacing(2);
    justify-items: center;
}