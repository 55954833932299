@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  grid-template-columns: 1fr;
  gap: utils.spacing(2);
  align-items: start;
  justify-content: space-between;
}

.paper {
  padding: utils.spacing(1);
}

.bools {
  display: flex;
  gap: utils.spacing(2);
  flex-wrap: wrap;
}

.matrixPaper {
  padding: utils.spacing(1, 2, 2, 2);
  display: inline-block;
  overflow: hidden;
}

.versionDetailsHeader {
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
}
