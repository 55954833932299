@use '@tsp-ui/core/sass/utils';

.root {
    padding: utils.spacing(1);
    display: grid;
    grid-template-columns: 1fr auto;
    gap: utils.spacing(0.5, 1);
}

.dialogRoot {
    min-width: 500px;
    min-height: 500px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(1);
}

.noComments {
    padding-left: utils.spacing(1);
}

.formWrapper {
    display: grid;
    grid-template-columns: auto auto;
}

.dialogActions {
    justify-content: stretch;
    padding-bottom: utils.spacing(1);
}

.form {
    flex: 1 0 auto;
}

.cardWrapper {
    display:flex;
    flex-direction: row;
    gap: utils.spacing(1);
}

.cardWrapperFromCurrentUser {
    align-self: self-end;
}

.comment {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    padding: utils.spacing(1, 1, 0.25, 1);
    margin: utils.spacing(0.5);
    align-self: flex-start;
}

.commentFromCurrentUser {
    background-color: var(--app-color_background-medium);
    border: none;
    min-width: 360px;
}

.commentContent {
    display: grid;
}

.inlineFormTextField {
    min-height: 40px;
    width: 300px;
    background: none;
    align-items: end;
}

.descriptionButtonContainer {
    display: flex;
}

.description {
    display: flex;
    width: 300px;
    white-space: wrap;
}

.createdAt {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: utils.spacing(0.5);
}
