@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
}

.expandedContent {
  overflow-y: auto;
}

.loans {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
  padding: utils.spacing(2, 2, 0, 2);
}

.noResultsPaper {
  padding: utils.spacing(1);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: utils.spacing(1);
}
