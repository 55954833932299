@use '@tsp-ui/core/sass/utils';

.root {
  overflow: hidden;
  padding: utils.spacing(0.5, 2);
}

.iconTypography {
  gap: utils.spacing(0.5);
  margin-right: utils.spacing(1);
  margin-left: utils.spacing(-1);
}
