@use '@tsp-ui/core/sass/utils';
@use '../MainNav.module';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: utils.spacing(1, 0);
  height: MainNav.$drawerWidth;
  width: MainNav.$drawerWidth;
  transition: background-color ease 250ms;

  &.selected {
    background-color: var(--app-color_primary-dark);

    &.dark {
      background-color: var(--app-color_primary_very-dark);
    }
  }

  &.subItem {
    height: auto;
  }
}

.icon {
  font-size: 28px;
}

.label {
  margin-top: utils.spacing(0.5);
  line-height: 1.1;
}
