@use '@tsp-ui/core/sass/utils';

.main {
  padding-bottom: utils.spacing(3);
}

.pageHeader {
  display: flex;
  align-items: center;
  gap: utils.spacing(4);
}

.root {
  display: grid;
  grid-template-columns: calc(75% - #{utils.spacing(4)}) 25%;
  grid-template-rows: auto auto;
  align-items: start;
  gap: utils.spacing(4);

  //TODO post-demo make this less fragile
  height: calc(100vh - 225px - #{utils.spacing(3)});
}

.autocomplete {
  width: 225px;
}

.mainContent {
  display: grid;
  gap: utils.spacing(3);
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content 1fr;
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(3);
}

.charts {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 260px 260px 260px;
  justify-content: space-around;
  gap: utils.spacing(0, 3);
  border-top: 1px solid var(--app-color_border);
  padding-block: utils.spacing(3);
  border-bottom: 1px solid var(--app-color_border);
}

.divider {
  margin-inline: utils.spacing(-1);
}

.loanRow {
  padding: utils.spacing(0.5, 2);
}

.iconTypography {
  margin-left: auto;
}

.chartContainer {
  position: relative;
  height: 260px;
  width: 260px;
  margin-top: utils.spacing(2);
}

.chartLabel {
  width: 175px;
  height: 175px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  & > * {
    pointer-events: all;
  }
}

// TODO post-demo make a component for this
.header {
  background-color: var(--app-color_background-medium);
  padding: utils.spacing(1.25);
  border-radius: var(--app-border-radius);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: utils.spacing(2);
}

.activityStream {
  margin-top: utils.spacing(2);
  padding-inline: utils.spacing(2);
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.activityStreamBottomRow {
  display: flex;
  align-items: baseline;
  gap: utils.spacing(2);
  justify-content: space-between;
  margin-top: utils.spacing(0.5);
}

.activityStreamItem {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: utils.spacing(2);
}

.avatar {
  background-color: var(--app-color_primary);
}

.activityStreamMessage b {
  font-weight: 500;
}
