@use "@tsp-ui/core/sass/utils";

.formQuestion {
  padding: utils.spacing(2, 2, 1, 2);
  display: inline-flex;
  flex-direction: column;
  gap: utils.spacing(1);
  max-width: 650px;
}

.formQuestionFields {
  display: grid;
  gap: utils.spacing(2);
  grid-template-columns: repeat(12, 1fr);
}

.hideSpinners {
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
