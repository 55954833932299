@use '@tsp-ui/core/sass/utils';

.root {
    padding: utils.spacing(6);
}

.dialogRoot {
    background-color: var(--app-color_background);
    border-radius: 4px;
}

.content {
    display: grid;
    grid-template-columns: 35% 65%;
}

.formWrapper {
    overflow-y: auto;
    padding: utils.spacing(2);
}

.formWrapper::-webkit-scrollbar,
.pricingResultsSection::-webkit-scrollbar {
    width: 16px;
}

.formWrapper::-webkit-scrollbar-thumb,
.pricingResultsSection::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 20px;
    border: 4px solid #fff;
}

.form {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(2);
}

.formLabel {
    font-weight: 700;
}

.detailsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: utils.spacing(2);
    margin-top: utils.spacing(1);
    align-items: center;
}

.addressField {
    grid-column: 1 / -1;
}

.pricingResultsSection {
    padding: utils.spacing(2);
    margin-left: utils.spacing(2);
    overflow-y: auto;
}

.pricingResultsTitle {
    margin-bottom: utils.spacing(1);
}

.resultPaper {
    width: 100%;
}

.pricingResults {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(2);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.actions {
    display: flex;
    justify-content: flex-start;
}

.amortType {
    align-self: flex-start;
}

.amortizationDetailsFields {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(1);
}