@use '@tsp-ui/core/sass/utils';

.paper {
  padding: utils.spacing(2);
}

.header {
  display: flex;
  gap: utils.spacing(3);
  justify-content: space-between;
}
