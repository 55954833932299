@use '@tsp-ui/core/sass/utils';

.content {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.expandedContent {
  display: flex;
  flex-direction: column;
  padding: utils.spacing(1, 2, 1, 2);
  gap: utils.spacing(1);
}
