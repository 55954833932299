@use '@tsp-ui/core/sass/utils';

.root {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(2);
}

.switchContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
}

.item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    width: 100%;
}