@use '@tsp-ui/core/sass/utils';

.adjusted {
  white-space: nowrap;
}

.cardInactive {
    text-decoration: line-through;
    color: gray;
}

.feeTypeDropdown {
    padding: 0;
    padding-top: utils.spacing(.5);
    padding-left: utils.spacing(1);
    padding-right: utils.spacing(1);
    min-width: 200px;
}

.feeCard {
  padding: utils.spacing(1);
  display: flex;
  gap: utils.spacing(2);
  align-items: center;
  justify-content: space-between;
  margin-bottom: utils.spacing(1);

  & > :first-child {
    flex: 1 1 auto;
  }
}

.chips {
  display: flex;
  gap: utils.spacing(1);
}

.chip {
  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
  padding: utils.spacing(0.25, 1);
  margin-right: utils.spacing(-1.3);
}

.labels {
  display: flex;
  align-items: baseline;
}

.maxLabel {
  margin-left: utils.spacing(1);
}

.buttons {
  display: flex;
}

.edit {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.variableEdit {
  display: grid;
  gap: utils.spacing(1);
  flex-wrap: wrap;
  align-items: flex-end;
  grid-template-columns: 1fr 1fr;
}

.percentageRow {
  display: flex;
  gap: utils.spacing(1);
  grid-column: span 2;
  align-items: flex-end;
}

.percentageInput {
  width: 93px; // min width to show ##.###%
}

.separationText {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: utils.spacing(1.125);
}

.loanAmount {
  flex: 1 1 auto;
}
