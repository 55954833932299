@use '@tsp-ui/core/sass/utils';

.listItem {
  display: flex;
  justify-content: space-between;
  gap: utils.spacing(2);
  text-wrap: nowrap;
}

// This is used to maintain a consistent width between a normal and a bold list item.
// The horizontal space of the boldened item is reserved by this hidden pseudo element.
.listItem span::after {
  display: block;
  content: attr(data-title);
  font-weight: 500;
  height: 1px;
  color: transparent;
  overflow: hidden;
  visibility: hidden;
}

.selected {
  font-weight: 500;
  min-width: auto;

  & > svg {
    margin-right: utils.spacing(1);
  }
}

.extraWidth {
  width: calc(100% + #{utils.spacing(1)});
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: utils.spacing(1);
}