@use '@tsp-ui/core/sass/utils';

.root {
  position: relative;
  overflow: hidden;
  padding: utils.spacing(.5, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: utils.spacing(2);
  white-space: nowrap;
  background-color: var(--app-color_background);

  .primaryText {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > :not(.completedOverlay) {
    z-index: 1;
  }

  .completedOverlay {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(100%);
  }

  &.done {
    transition: border 250ms ease;
    border-color: var(--app-color_success);

    .completedOverlay {
      background-color: rgb(237, 247, 237);
    }
  }

  &.error {
    transition: border 250ms ease;
    border-color: var(--app-color_error);

    .completedOverlay {
      background-color: rgb(253, 237, 237);
    }
  }

  &.done, &.error {
    .completedOverlay {
      transition: transform 250ms ease;
      transform: none;
    }
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  gap: utils.spacing(1);
}
