@use '@tsp-ui/core/sass/utils';


.sideEffectCard {
    padding: utils.spacing(0.5, 1);
    display: flex;
    flex-direction: column;
    gap: utils.spacing(0.5);
    overflow: hidden;
    background-color: var(--app-color_background);

    &.isEditing {
        padding: utils.spacing(1);
    }
}

.headerEditing {
  display: flex;
  gap: utils.spacing(0.5);
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  flex: 1;
  min-width: 150px;
}

.headerContainer {
  padding-left: utils.spacing(1);
  background-color: var(--app-color_background-medium);
  display: flex;
  gap: utils.spacing(0.5);
  align-items: center;
  justify-content: space-between;
  margin: utils.spacing(-0.5, -1, 0, -1);
  line-height: 1;
  flex: 1;
  min-width: 150px;
}

.fullWidth {
  width: 100%;
}

.buttons {
  display: flex;
}