@use '@tsp-ui/core/sass/utils';

.root {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: utils.spacing(2);
}

.address {
  > div {
    gap: utils.spacing(1, 2)
  }

}

.borrowerRow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: utils.spacing(2);
}
