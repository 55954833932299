@use '@tsp-ui/core/sass/utils';

.root {
  display: grid;
  gap: utils.spacing(3);
  align-items: start;
  grid-template-columns: auto 1fr;

  & > div {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(3);
  }

  & > div:nth-child(2) {
    justify-self: end;
  }
}

.viewPermissions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: utils.spacing(3);
}

.viewPermission {
  text-align: left;
}

.viewPermissionLevel {
  text-align: right;
}

.sectionViewHeader {
  background-color: var(--app-color_background-medium);
  border-radius: var(--app-border-radius);
  padding: utils.spacing(0.5, 1);
  font-weight: 500;
}

.rolesView {
  padding: utils.spacing(1, 1, 0, 1);
}
