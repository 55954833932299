@use '@tsp-ui/core/sass/utils';

.entityGroupContainer {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(1);
}

.showInactive {
  margin-right: 0;
}

.iconButton {
  margin-left: auto;
}

.productSetHeader {
  display: flex;
  align-items: center;
  margin-block: utils.spacing(-1);

  &:not(:nth-child(2)) {
    margin-top: 0;
  }
}

.expandedContent {
  display: flex;
  flex-direction: column;
  padding: utils.spacing(1, 2, 1, 2);
  gap: utils.spacing(1);
  max-width: 1000px;
  margin: auto;
}
