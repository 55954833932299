@use '@tsp-ui/core/sass/utils';

.root {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(2);
    width: 400px;
}

.percentageRow {
    display: flex;
    gap: utils.spacing(1);
    align-items: flex-end;
}

.percentageInput {
    width: 93px; // min width to show ##.###%
}

.separationText {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: utils.spacing(1.125);
}

.loanAmount {
    flex: 1;
}

.minMax {
    display: flex;
    gap: utils.spacing(2);
}