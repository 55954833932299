@use '@tsp-ui/core/sass/utils';

.paper {
  overflow: hidden;

  &.formShown {
    min-height: 500px;
    pointer-events: none;
  }
}

.editFormContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  pointer-events: all;
}
