@use '@tsp-ui/core/sass/utils';

.root {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(1);
}

.noteRate {
    padding: utils.spacing(0.5, 2);
    background-color: var(--app-color_primary);
    color: white;
}

.rates {
    padding: utils.spacing(0, 2, 1, 2);
}

.rateWrapper {
    display: flex;
    align-items: center;
    gap: utils.spacing(1);
}

.radio {
    margin-right: utils.spacing(-1);
}
