@use "@tsp-ui/core/sass/utils";

.content {
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: utils.spacing(1, 3);
}

.selected {
  font-weight: 500;
}

.extraWidth {
  width: calc(100% + #{utils.spacing(1)});
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fullWidth {
  grid-column: span 2;
}
